<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Config from "../../../../config.js";
import axios from "axios";
import moment from "moment";
import { Helper } from "../../../../helper.js";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import { Circle8 } from "vue-loading-spinner";

/**
 * Advanced table component
 */
export default {
  mixins: [Helper],
  page: {
    // title: "Advanced Table",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, DatePicker, Circle8, Multiselect },
  data() {
    return {
      isShowDetail: false,
      userStart: JSON.parse(localStorage.getItem("user")),
      data_url: Config,

      partner_id: "",
      sektor_id: "",
      paket_pekerjaan_id: "",
      dataHeaderDetail: "",
      dataDetail: "",
      searchProvinsiId: "",
      isFromNotif: false,

      dataSelectClient: [],
      dataSelectSector: [],
      dataSelectProvinsi: [],
      

      loading: false,
      searchYear: "",
      searchClientId: "",
      searchSector: "",
      dataTableAllAssesment: [],
      title: this.$root.$t("pageKarya.PublicAssesment.title"),
      items: [
        {
          text: "All Assesment",
          href: "/",
        },
        {
          text: "View",
          active: true,
        },
      ],
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "kodefikasi", sortable: true, label: "Kodefikasi" },
        { key: "sektor_name", sortable: true, label: "Sector Name" },
        {
          key: "paket_pekerjaan_name",
          sortable: true,
          label: "Project Package",
        },
        { key: "issue", sortable: true, label: "Issue" },
        { key: "score", sortable: true, label: "Rating" },
        { key: "detail", sortable: false, label: "Detail" },
      ],
    };
  },
  computed: {
    rows() {
      return this.dataTableAllAssesment.length;
    },

    searchYear_computed: {
      get() {
        return this.searchYear;
      },
      set(val) {
        if (val == null) {
          this.searchYear = "";
        } else {
          this.searchYear = val;
        }

        this.ApiCallDataAssesment();
      },
    },

    searchSector_computed: {
      get() {
        return this.searchSector;
      },
      set(val) {
        if (val == null) {
          this.searchSector = "";
        } else {
          this.searchSector = val;
        }

        this.ApiCallDataAssesment();
      },
    },

    searchClientId_computed: {
      get() {
        return this.searchClientId;
      },
      set(val) {
        if (val == null) {
          this.searchClientId = "";
        } else {
          this.searchClientId = val;
        }

        this.ApiCallDataAssesment();
      },
    },

    searchProvinsiId_computed: {
      get() {
        return this.searchProvinsiId;
      },
      set(val) {
        if (val == null) {
          this.searchProvinsiId = "";
        } else {
          this.searchProvinsiId = val;
        }

        this.ApiCallDataAssesment();
      },
    },
    /**
     * Total no. of records
     */
  },
  mounted() {
    if (Config.modeDev) console.log(this.$route.params);

    if (!this.emObj(this.$route.params.param_provinsi)) {
      this.searchProvinsiId = {
        id: this.$route.params.param_provinsi.id,
        name: this.$route.params.param_provinsi.name_provin,
        code: "",
      };
    }

    if (!this.emObj(this.$route.params.param_client)) {
      this.searchClientId = {
        id: this.$route.params.param_client.id,
        name: this.$route.params.param_client.name,
        code: "",
      };
    }

    if (!this.emObj(this.$route.params.param_year)) {
      this.searchYear = new Date(this.$route.params.param_year);
    }

    if (!this.emObj(this.$route.params.param_segment)) {
      this.searchSector = {
        id: this.$route.params.param_segment.id,
        name: this.$route.params.param_segment.name,
        code: "",
      };
    }

    let params = sessionStorage.getItem("params");

    if (params != null) {
      params = JSON.parse(sessionStorage.getItem("params"));
      sessionStorage.removeItem("params");

      this.partner_id = params.partner_id;
      this.sektor_id = params.sektor_id;
      this.paket_pekerjaan_id = params.paket_pekerjaan_id;
      this.ApiCallDataAssesmentNotif(params);
    } else {
      this.ApiCallDataAssesment();
    }

    this.ApiCallDataClient();
    this.ApiCallDataProvinsi();
    this.ApiCallDataSector();
  },
  methods: {
    backList() {
      this.isShowDetail = false;
    },

    showDetail(data) {
      this.dataHeaderDetail = data.item;
      this.partner_id = data.item.partner_id;
      this.sektor_id = data.item.sektor_id;
      this.paket_pekerjaan_id = data.item.paket_pekerjaan_id;
      this.ApiCallDataAssesmentDetail();
    },

    async ApiCallDataClient() {
      this.loading = true;
      let formData = new URLSearchParams();
      formData.append("perpage", 9999);
      formData.append("page", 1);

      if (Config.modeDev) console.log(this.userStart);
      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.getContact,
          formData,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              // "Accept": "application/json",
              // "Content-Type": "application/x-www-form-urlencoded" // "text/html"
            },
          }
        )
        .then((res) => {
          if (Config.modeDev) console.log(res);
          if (res.data.codestatus == "S") {
            this.dataSelectClient = res.data.resultdata;
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            if (this.is_runTest == false) {
              console.log(error);
            }
          }
        });

      this.loading = false;
    },

    async ApiCallDataSector() {
      this.loading = true;
      let formData = new URLSearchParams();
      formData.append("perpage", 9999);
      formData.append("page", 1);

      if (Config.modeDev) console.log(this.userStart);
      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.getSegmentasiOwner,
          formData,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              // "Accept": "application/json",
              // "Content-Type": "application/x-www-form-urlencoded" // "text/html"
            },
          }
        )
        .then((res) => {
          if (Config.modeDev) console.log(res);
          if (res.data.codestatus == "S") {
            this.dataSelectSector = res.data.resultdata;
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            if (this.is_runTest == false) {
              console.log(error);
            }
          }
        });

      this.loading = false;
    },

    clearProvinsi() {
      this.searchProvinsiId = "";
      this.ApiCallDataAssesment();
    },
    clearClient() {
      this.searchClientId = "";
      this.ApiCallDataAssesment();
    },

    clearSector() {
      this.searchSector = "";
      this.ApiCallDataAssesment();
    },
    async ApiCallDataProvinsi() {
      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.getCountryState,
          null,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data", // "application/x-www-form-urlencoded" // "text/html"
            },
          }
        )
        .then((res) => {
          if (Config.modeDev) console.log(res);
          if (res.data.codestatus == "S") {
            this.dataSelectProvinsi = res.data.resultdata;
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            if (this.is_runTest == false) {
              console.log(error);
            }
          }
        });
    },

    async ApiCallDataAssesmentNotif(data) {
      if (Config.modeDev) console.log(this.userStart);

      let dataas = [];
      for (let i = 0; i < data.data_notif.length; i++) {
        dataas[i] = data.data_notif[i].assessment_id;
      }

      dataas = JSON.stringify({
        ids: dataas,
      });

      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN +
            this.data_url.getPublicAssessmentFullJSONType,
          dataas,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              // "Accept": "application/json",
              "Content-Type": "application/json", // "application/x-www-form-urlencoded" // "text/html"
            },
          }
        )
        .then((res) => {
          if (Config.modeDev) console.log(res);
          if (res.data.codestatus == "S") {
            this.isFromNotif = true;
            this.dataTableAllAssesment = res.data.resultdata;

            if (Config.modeDev) console.log(res.data.resultdata);
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            if (this.is_runTest == false) {
              console.log(error);
            }
          }
        });

      this.loading = false;
    },

    async ApiCallDataAssesment() {
      this.loading = true;

      let formData = new URLSearchParams();
      formData.append("perpage", 9999);
      formData.append("page", 1);

      if (this.searchYear != "") {
        formData.append("year", moment(this.searchYear).format("YYYY"));
      }

      if (this.searchClientId != "") {
        formData.append("partner_id", this.searchClientId.id);
      }

      if (this.searchProvinsiId != "") {
        formData.append("state_id", this.searchProvinsiId.id);
      }

      if (this.searchSector != "") {
        formData.append("segment_id", this.searchSector.id);
      }

      if (Config.modeDev) console.log(this.userStart);
      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.getPublicAssessment,
          formData,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              // "Accept": "application/json",
              // "Content-Type": "application/x-www-form-urlencoded" // "text/html"
            },
          }
        )
        .then((res) => {
          if (Config.modeDev) console.log(res);
          if (res.data.codestatus == "S") {
            this.isFromNotif = false;
            this.dataTableAllAssesment = res.data.resultdata;
            if (Config.modeDev) console.log(this.dataTableAllAssesment);
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            if (this.is_runTest == false) {
              console.log(error);
            }
          }
        });

      this.loading = false;
    },

    cekScoreFix(da) {
      try {
        return da.toFixed(1);
      } catch (error) {
        return "";
      }
    },

    async ApiCallDataAssesmentDetail() {
      this.loading = true;

      let formData = new URLSearchParams();
      formData.append("perpage", 9999);
      formData.append("page", 1);
      formData.append("partner_id ", this.partner_id);
      formData.append("sektor_id ", this.sektor_id);
      formData.append("paket_pekerjaan_id ", this.paket_pekerjaan_id);

      if (Config.modeDev) console.log(this.userStart);
      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN +
            this.data_url.getPublicAssessmentDetail,
          formData,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              // "Accept": "application/json",
              // "Content-Type": "application/x-www-form-urlencoded" // "text/html"
            },
          }
        )
        .then((res) => {
          if (Config.modeDev) console.log(res);
          if (res.data.codestatus == "S") {
            this.dataDetail = res.data.resultdata;
            this.isShowDetail = true;
            if (Config.modeDev) console.log(this.dataDetail);
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            if (this.is_runTest == false) {
              console.log(error);
            }
          }
        });

      this.loading = false;
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card" v-if="!isShowDetail">
          <div class="card-body">
            <h4 class="card-title">Data All Assessment</h4>
            <div class="row" v-if="isFromNotif">
              <div class="col-lg-4 offset-lg-4">
                 <b-alert
                    variant="info"
                    dismissible
                    fade
                    :show="isFromNotif"
                    @dismissed="ApiCallDataAssesment"
                  >
                    List New Assessment Auto Search By Notification
                  </b-alert>
              </div>
            </div>
            <div class="row mt-4 align-items-end">
              <div class="col-sm-12 col-md-3">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-9">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <div
                    class="d-inline-flex align-items-center pr-3 position-relative"
                    v-if="!isFromNotif"
                  >
                    Provinsi : &nbsp;
                    <multiselect
                      style="width: 150px"
                      :clearOnSelect="true"
                      :closeOnSelect="true"
                      :options="dataSelectProvinsi"
                      v-model="searchProvinsiId_computed"
                      :show-labels="false"
                      label="name"
                    ></multiselect>
                    <icon
                      @click="clearProvinsi()"
                      v-if="searchProvinsiId != ''"
                      class="ri-close-circle-fill position-absolute"
                      style="font-size: 20px; right: 20px"
                    ></icon>
                  </div>
                  <div
                    class="d-inline-flex align-items-center pr-3 position-relative"
                    v-if="!isFromNotif"
                  >
                    Segment : &nbsp;
                    <multiselect
                      style="width: 150px"
                      :show-labels="false"
                      :clearOnSelect="true"
                      :closeOnSelect="true"
                      :options="dataSelectSector"
                      v-model="searchSector_computed"
                      label="name"
                    ></multiselect>
                    <icon
                      @click="clearSector()"
                      v-if="searchSector != ''"
                      class="ri-close-circle-fill position-absolute"
                      style="font-size: 20px; right: 20px"
                    ></icon>
                  </div>
                  <div
                    class="d-inline-flex align-items-center pr-3 position-relative"
                    v-if="!isFromNotif"
                  >
                    Client : &nbsp;
                    <multiselect
                      style="width: 200px"
                      :show-labels="false"
                      :clearOnSelect="true"
                      :closeOnSelect="true"
                      :options="dataSelectClient"
                      v-model="searchClientId_computed"
                      label="name"
                    ></multiselect>
                    <icon
                      @click="clearClient()"
                      v-if="searchClientId != ''"
                      class="ri-close-circle-fill position-absolute"
                      style="font-size: 20px; right: 20px"
                    ></icon>
                  </div>
                  <label
                    class="d-inline-flex align-items-center pr-0 dpvp"
                    v-if="!isFromNotif"
                  >
                    <span class="mr-2">Year </span>
                    <date-picker
                      v-model="searchYear_computed"
                      type="year"
                      lang="en"
                      placeholder="Select Year"
                    ></date-picker>
                  </label>
                  <label class="d-inline-flex align-items-center ml-2">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>

            <Circle8 v-if="loading"></Circle8>
            <div class="table-responsive mb-0" v-if="!loading">
              <b-table
                :items="dataTableAllAssesment"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
              >
                <template v-slot:cell(kodefikasi)="data">
                  <a
                    class="text-black"
                    v-b-tooltip.hover
                    :id="'tt' + data.item.state_id + data.item.kodefikasi + data.item.paket_pekerjaan_id + data.item.sektor_id"
                  >
                    #{{ data.item.kodefikasi }}
                  </a>

                  <b-tooltip
                    :target="'tt' + data.item.state_id + data.item.kodefikasi + data.item.paket_pekerjaan_id + data.item.sektor_id"
                    placement="rightbottom"
                  >
                    <div
                      style="
                        width: 200px;
                        min-height: 60px;
                        position: relative;
                        margin-top: -10px;
                        margin-left: -10px;
                      "
                    >
                      <span
                        style="
                          font-weight: bold;
                          text-align: center;
                          font-size: 40px;
                          color: rgba(255, 255, 255, 0.2);
                        "
                      >
                        {{ userStart.ad_user_name }}</span
                      >
                      <div
                        class="text-white"
                        style="
                          padding-top: 3px;
                          font-size: 14px;
                          text-align: center;
                          margin: auto;
                          position: absolute;
                          top: 25%;
                          bottom: 25%;
                          left: 5%;
                          right: 5%;
                          min-height: 60px;
                        "
                      >
                        {{ data.item.partner_name }}
                      </div>
                    </div>
                  </b-tooltip>
                </template>

                <template v-slot:cell(score)="data">
                  <p class="font-poppins">
                    <span style="color: blue; font-size: 20px">{{
                      cekScoreFix(data.item.score)
                    }}</span
                    ><span style="font-size: 16px; font-weight: bold"></span>
                  </p>
                </template>

                <template v-slot:cell(issue)="data">
                  <div
                    :title="v.name"
                    class="circlee2 mb-2 float-right"
                    style="width: 32px; height: 32px"
                    v-for="(v, i) in data.item.issues"
                    v-bind:key="i"
                  >
                    <img
                      style="margin: auto; height: 24px; margin-top: 5px"
                      :src="'' + v.image"
                    />
                  </div>
                </template>

                <template v-slot:cell(detail)="data">
                  <a
                    class="mr-3 text-primary ml-3"
                    v-b-tooltip.hover
                    style="cursor: pointer"
                    title="View Detail"
                    @click="showDetail(data)"
                  >
                    <i
                      class="far fa-eye font-size-18"
                      style="margin-top: -5px"
                    ></i>
                  </a>
                </template>
              </b-table>
            </div>
            <div class="row mb-5">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card" v-if="isShowDetail">
          <div class="card-body">
            <div class="card-title">
              <div class="text-right">Data Assessment -> Detail</div>
              <button class="btn btn-warning" @click="backList">Kembali</button>
            </div>

            <div class="card">
              <div
                class="card-header text-white"
                style="background-color: #172957"
              >
                <div class="font-poppins float-left" style="font-size: 20px">
                  <a
                    class="text-black text-white"
                    v-b-tooltip.hover
                    :id="'tde' + dataHeaderDetail.sektor_id"
                    >Information Client #{{ dataHeaderDetail.kodefikasi }}
                  </a>

                  <b-tooltip
                    :target="'tde' + dataHeaderDetail.sektor_id"
                    placement="rightbottom"
                  >
                    <div
                      style="
                        width: 200px;
                        min-height: 60px;
                        position: relative;
                        margin-top: -10px;
                        margin-left: -10px;
                      "
                    >
                      <span
                        style="
                          font-weight: bold;
                          text-align: center;
                          font-size: 40px;
                          color: rgba(255, 255, 255, 0.2);
                        "
                      >
                        {{ userStart.ad_user_name }}</span
                      >
                      <div
                        class="text-white"
                        style="
                          padding-top: 3px;
                          font-size: 14px;
                          text-align: center;
                          margin: auto;
                          position: absolute;
                          top: 25%;
                          bottom: 25%;
                          left: 5%;
                          right: 5%;
                          min-height: 60px;
                        "
                      >
                        {{ dataHeaderDetail.partner_name }}
                      </div>
                    </div>
                  </b-tooltip>
                </div>
                <div class="font-poppins float-right">
                  Jumlah Karya
                  <div
                    class="circlee"
                    style="
                      width: 25px;
                      display: inline-block;
                      background-color: #ffffff;
                      color: #172957;
                      font-size: 16px;
                    "
                  >
                    {{ dataHeaderDetail.total_karya }}
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <table class="table">
                      <tr>
                        <th class="font-poppins" style="font-weight: bold">
                          Sector
                        </th>
                        <td>{{ dataHeaderDetail.sektor_name }}</td>
                        <th class="font-poppins" style="font-weight: bold">
                          Issue
                        </th>
                        <td>
                          <div
                            :title="v.name"
                            class="circlee2 mb-2 float-left"
                            style="width: 32px; height: 32px"
                            v-for="(v, i) in dataHeaderDetail.issues"
                            v-bind:key="i"
                          >
                            <img
                              style="
                                margin: auto;
                                height: 24px;
                                margin-top: 5px;
                              "
                              :src="'' + v.image"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="font-poppins" style="font-weight: bold">
                          Jenis Packet
                        </th>
                        <td>{{ dataHeaderDetail.paket_pekerjaan_name }}</td>
                        <th class="font-poppins" style="font-weight: bold">
                          Rating
                        </th>
                        <td>
                          <span style="color: blue; font-size: 20px">{{
                            dataHeaderDetail.score.toFixed(1)
                          }}</span
                          ><span
                            style="font-size: 16px; font-weight: bold"
                          ></span>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="card" v-for="(v, i) in dataDetail" v-bind:key="i">
              <div class="card-body px-0">
                <p
                  class="card-header text-white"
                  style="background-color: #009ea9"
                >
                  {{ v.name }}
                </p>
                <div
                  class="row mt-3 px-4"
                  v-for="(v1, i1) in v.data_question"
                  v-bind:key="i1"
                >
                  <div class="col-12">
                    <span
                      class="font-poppins"
                      style="font-weight: bold; font-size: 16px"
                      >{{ v1.title }}</span
                    >
                    <br /><br />
                    <table
                      class="table ml-3"
                      v-for="(v2, i2) in v1.data_karya"
                      v-bind:key="i2"
                    >
                      <tr>
                        <td class="font-poppins" style="font-weight: bold">
                          Project {{ i2 + 1 }}
                        </td>
                        <td class="text-right font-poppins">
                          <span class="font-weight-bold">Value Answer : </span>
                          {{ v2.nilai }}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">{{ v2.value }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
